import React from 'react'
import DApproval from "../Listings/dentistApprovel"
import Nav from "../nav/nav"
const Dash = () => {
  return (
    <div>
        <Nav/>
        <DApproval/>
    </div>
  )
}

export default Dash