import React from 'react'
import Forgot from "../Auth/forgot"
const Forget = () => {
  return (
    <div>
        <Forgot/>
    </div>
  )
}

export default Forget