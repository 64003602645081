import React from 'react'
import Privacy from "../profile/privacy"
import Nav from "../nav/nav"
const Privaci = () => {
  return (
    <div>
        <Nav/>
        <Privacy/>
    </div>
  )
}

export default Privaci