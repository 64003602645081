import { optionReducer, sidebar, resetReducer, dran } from "./option";
import {
  authStateReducer,
  tokenReducer,
  idSet,
  DataUser,
  caseId,
  RoleSet
} from "./data";
import { combineReducers } from "redux";

const allReducers = combineReducers({
  option: optionReducer,
  sidebar: sidebar,
  authState: authStateReducer,
  token: tokenReducer,
  reset: resetReducer,
  idSet: idSet,
  dataUser: DataUser,
  caseId: caseId,
  dran: dran,
  RoleSet: RoleSet,
});

export default allReducers;
