import React, { useState, useEffect, useRef, useMemo } from "react";
import * as THREE from "three";
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader";
import logo1 from "../../Images/logo.png";
import logo2 from "../../Images/logo2.png";
import center from "../../Images/Center.svg";
import centerA from "../../Images/Center_Active.svg";
import left from "../../Images/Left.svg";
import leftA from "../../Images/Left_Active.svg";
import right from "../../Images/Right.svg";
import rightA from "../../Images/Right_Active.svg";
import { Spin } from "antd";
import upper from "../../Images/Upper.svg";
import upperA from "../../Images/Upper_Active.svg";
import lower from "../../Images/Lower.svg";
import lowerA from "../../Images/Lower_Active.svg";
import clock from "../../Images/clock.svg";
import playy from "../../Images/play.png";
import pausee from "../../Images/pause.png";
import {
  DownOutlined,
  DownloadOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { Canvas, extend, useLoader, useThree } from "@react-three/fiber";
import { Stage, PresentationControls } from "@react-three/drei";
import { Switch } from "antd";
import axios from "axios";
import "babylonjs-loaders";
import { useSelector } from "react-redux";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
extend({ OrbitControls });
let upperUrl = [];
let plyFilesUrl = [];
const PlayerViewer = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [pDetails, setPdetails] = useState(false);
  const [tDetails, setTdetails] = useState(false);
  const [value, setValue] = useState(0);
  const dran = useSelector((state) => state.dran);
  const logo = dran ? logo2 : logo1;
  const [play, setPlay] = useState(false);
  const [qPlay, setqPlay] = useState(false);
  const [upperShow, setUpperShow] = useState(false);
  const [lowerShow, setLowerShow] = useState(false);
  const [plyFile, setPlyFile] = useState([]);
  const [showLeftView, setShowLeftView] = useState(false);
  const [showCenter, setShowCenter] = useState(true);
  const [showRightView, setShowRightView] = useState(false);
  const [upperFile, setUpperFile] = useState([]);
  const [temp, setTemp] = useState([]);
  const [inquiries, setInquiries] = useState("");
  const [pdf, setPdf] = useState("");
  const [len, setLen] = useState(0);
  const [count, setCount] = useState(0);
  const caseId = useSelector((state) => state.caseId);
  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const [loadingF, setLoadingF] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const controlsRef = useRef();
  const toggleDarkMode = (checked) => {
    setIsDarkMode(checked);
  };

  // const Model = ({ file }) => {
  //   const ply = useLoader(PLYLoader, file);

  //   return (
  //     <mesh receiveShadow={false} castShadow={false}>
  //       <bufferGeometry attach="geometry" {...ply} />
  //       <meshStandardMaterial
  //         attach="material"
  //         vertexColors={true}
  //         metalness={0}
  //         roughness={0}
  //         visible={true}
  //       />
  //     </mesh>
  //   );
  // };

  const TeethModel = ({ upperFile, lowerFile, spacing = 1.5 }) => {
    const upperPly = useLoader(PLYLoader, upperFile);
    const lowerPly = useLoader(PLYLoader, lowerFile);
    const upperRef = useRef();
    const lowerRef = useRef();
    const { camera } = useThree();

    const upperPosition = [0, lowerShow ? -spacing / 2 + 20 : spacing / 2, 0];
    const lowerPosition = [0, upperShow ? spacing / 2 - 20 : -spacing / 2, 0];

    useEffect(() => {
      if (upperShow) {
        upperRef.current.rotation.set(-Math.PI / 2, 0, 0);
        camera.fov = 80;
        camera.updateProjectionMatrix();
      } else if (lowerShow) {
        lowerRef.current.rotation.set(Math.PI / 2, 0, 0);
        camera.fov = 80;
        camera.updateProjectionMatrix();
      } else if (showLeftView) {
        upperRef.current.rotation.set(0, Math.PI / 2, 0);
        lowerRef.current.rotation.set(0, Math.PI / 2, 0);
        camera.fov = 80;
        camera.updateProjectionMatrix();
      } else if (showRightView) {
        upperRef.current.rotation.set(0, -Math.PI / 2, 0);
        lowerRef.current.rotation.set(0, -Math.PI / 2, 0);
        camera.fov = 80;
        camera.updateProjectionMatrix();
      } else {
        upperRef.current.rotation.set(0, 0, 0);
        lowerRef.current.rotation.set(0, 0, 0);
        camera.fov = 50;
        camera.updateProjectionMatrix();
      }
    }, [showLeftView, showRightView, upperShow, lowerShow, camera]);

    return (
      <>
        <ambientLight intensity={10} />
        <directionalLight position={[5, 10, 5]} intensity={10} />
        <directionalLight position={[-5, -10, -5]} intensity={10} />
        {!lowerShow && (
          <mesh
            ref={upperRef}
            receiveShadow={false}
            castShadow={false}
            position={upperPosition}
          >
            <bufferGeometry attach="geometry" {...upperPly} />
            <meshStandardMaterial
              attach="material"
              vertexColors
              roughness={0.5}
              metalness={0.1}
            />
          </mesh>
        )}
        {!upperShow && (
          <mesh
            ref={lowerRef}
            receiveShadow={false}
            castShadow={false}
            position={lowerPosition}
          >
            <bufferGeometry attach="geometry" {...lowerPly} />
            <meshStandardMaterial
              attach="material"
              vertexColors
              roughness={0.5}
              metalness={0.1}
            />
          </mesh>
        )}
      </>
    );
  };
  useEffect(() => {
    console.log("upppp", upperFile);
    console.log("upppp", plyFile);
  }, [upperFile, plyFile]);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://dubaismiledl.com/api/case/get-case-by-id/${caseId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        setLoading(false);
        setInquiries(data?.data);
        console.log(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    const fetchDataSequentially = async (urls) => {
      const tempe = [];
      plyFilesUrl = [];
      upperUrl = [];
      setLoadingF(true);
      console.log("okkk");
      try {
        console.log("okkk1", urls);
        for (let i = 0; i < urls.length; i++) {
          setCount(i + 1);
          const response = await axios.get(urls[i].fileContent);
          const data = response.data;
          console.log("DATAA", urls[i].fileContent, i);
          tempe.push(data);
          // setData(prevData => [...prevData, data]);

          if (i === urls.length - 1) {
            setLoadingF(false);
            temp.forEach((file, index) => {
              if (index % 2 === 0) {
                plyFilesUrl.push(file.fileContent);
              } else {
                upperUrl.push(file.fileContent);
              }
            });
            setPlyFile(plyFilesUrl);
            setUpperFile(upperUrl);
          }
        }
      } catch (error) {
        console.error("Error fetching .ply files:", error);
        setLoading(false);
      }
    };
    fetchDataSequentially(temp);
  }, [temp]);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://dubaismiledl.com/api/CaseFiles/get-casefiles?id=${caseId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setLoading(false);
        console.log("C", data);
        const plyFiles = data?.data?.$values.filter(
          (file) => file.fileType === ".ply" && file.createdFor === "Comment"
        );
        const pdfFile = data?.data?.$values.filter(
          (file) => file.fileType === ".pdf"
        );
        setLen(plyFiles.length);
        setTemp(plyFiles);
        setPdf(pdfFile.length > 0 ? pdfFile[0]?.fileContent : "");
        // plyFiles.forEach((file, index) => {
        //   if (index % 2 === 0) {
        //     plyFilesUrl.push(file.fileContent);
        //   } else {
        //     upperUrl.push(file.fileContent);
        //   }
        // });

        // setPlyFile(plyFilesUrl);
        // setUpperFile(upperUrl);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  // useEffect(() => {
  //   const fetchData = async (urls, setData) => {
  //     setLoading(true);
  //     try {
  //       const responses = await Promise.all(urls.map(url => axios.get(url)));
  //       const data = responses.map(response => response.data);
  //       setData(data);
  //     } catch (error) {
  //       console.error('Error fetching .ply files:', error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (plyFile.length > 0) {
  //     fetchData(plyFile, setPlyFile);
  //   }

  //   if (upperFile.length > 0) {
  //     fetchData(upperFile, setUpperFile);
  //   }
  // }, [plyFile, upperFile]);

  useEffect(() => {
    console.log("Currrrrr", currentIndex);
    console.log("play", play);
    console.log("qplay", qPlay);
  }, [plyFile, currentIndex, play, qPlay]);

  const handleNext = () => {
    console.log(currentIndex);
    if (currentIndex < plyFile.length - 1) setCurrentIndex(currentIndex + 1);
  };

  const handlePrevious = () => {
    console.log(currentIndex);
    if (currentIndex > 0) setCurrentIndex(currentIndex - 1);
  };

  useEffect(() => {
    let intervalId;
    if (play) {
      intervalId = setInterval(
        () => {
          setCurrentIndex((prevIndex) =>
            prevIndex + 1 < plyFile.length ? prevIndex + 1 : setPlay(false)
          );
        },
        qPlay ? 200 : 500
      );
    } else {
      setCurrentIndex(0);
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [play]);
  // useEffect(() => {
  //   let intervalId;
  //   if (qPlay) {
  //     intervalId = setInterval(() => {
  //       setCurrentIndex((prevIndex) =>
  //         prevIndex + 1 < plyFile.length ? prevIndex + 1 : setqPlay(false)
  //       );
  //     }, 500);
  //   } else {
  //     setCurrentIndex(0);
  //     clearInterval(intervalId);
  //   }

  //   return () => clearInterval(intervalId);
  // }, [qPlay]);

  return (
    <main>
      <div
        className={`w-full flex justify-center p-2 h-screen flex-col ${
          isDarkMode ? "bg-[#282828]" : "bg-gray-50"
        }`}
      >
        <div
          className={`w-full h-[80%] flex flex-col items-center justify-start relative`}
        >
          <div className="flex z-10 flex-row justify-between sm:px-12 p-0 w-full pt-12">
            <div className="flex flex-col justify-start">
              <div className="space-y-4  mobile:w-48">
                <div
                  className={`w-full rounded-md ${
                    isDarkMode
                      ? "bg-gray-600 text-white"
                      : "bg-white text-gray-700"
                  }`}
                >
                  <div
                    className={`w-full h-11 gap-2 rounded-lg border border-gray-60 cursor-pointer flex justify-between items-center px-4 ${
                      isDarkMode
                        ? " bg-gray-700 border-gray-600 text-white"
                        : "bg-blue-50 border-blue-300 text-gray-700"
                    }`}
                  >
                    <span className="text-sm font-normal">
                      Treatment Details
                    </span>
                    <DownOutlined
                      onClick={() => {
                        if (pDetails) setPdetails(false);
                        setTdetails(!tDetails);
                      }}
                    />
                  </div>
                  {tDetails && (
                    <div className="p-2 flex flex-col gap-2">
                      <div>
                        <h1>No of Aligner Sets</h1>
                        <p>{inquiries?.numberOfAligner}</p>
                      </div>
                      <div className="w-full h-[1px] bg-gray-300"></div>
                      <div>
                        <h1>Case Complexity</h1>
                        <p className="capitalize">{inquiries?.complexity}</p>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`w-full rounded-md ${
                    isDarkMode
                      ? "bg-gray-600 text-white"
                      : "bg-white text-gray-700"
                  }`}
                >
                  <div
                    className={`w-full h-11 rounded-lg border border-gray-60 cursor-pointer flex justify-between items-center px-4 ${
                      isDarkMode
                        ? " bg-gray-700 border-gray-600 text-white"
                        : "bg-blue-50 border-blue-300 text-gray-700"
                    }`}
                  >
                    <span className="text-sm font-normal">Patient Details</span>
                    <DownOutlined
                      onClick={() => {
                        if (tDetails) setTdetails(false);
                        setPdetails(!pDetails);
                      }}
                    />
                  </div>
                  {pDetails && (
                    <div className="p-2 flex flex-col gap-2">
                      <div>
                        <h1>Name</h1>
                        <p>
                          {inquiries?.firstName} {inquiries?.lastName}
                        </p>
                      </div>
                      <div className="w-full h-[1px] bg-gray-300"></div>
                      <div>
                        <h1>Gender</h1>
                        <p className="capitalize">{inquiries?.gender}</p>
                      </div>
                      <div className="w-full h-[1px] bg-gray-300"></div>
                      <div>
                        <h1>ID</h1>
                        <p>{inquiries?.$id}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <img
              className="sm:w-[7rem] sm:h-[5rem] w-[5rem] h-[4rem]"
              src={logo}
              alt="Logo"
            />
            <div className="flex flex-col gap-y-4">
              <button
                onClick={() => {
                  console.log(pdf);
                  window.open(pdf, "_blank");
                }}
                type="button"
                className="font-medium focus:outline-none focus:ring-4 hover:bg-primary-hover sm:w-[185px] w-[160px] focus:ring-primary-faded dark:bg-primary dark:hover:bg-primary-hover dark:focus:ring-primary-hover text-sm h-[48px] p-2.5 bg-blue-600 rounded-lg justify-center items-center inline-flex text-white m-0 flex items-center justify-center"
              >
                <DownloadOutlined className="font-bold text-xl" />
                <span className="ml-2">Download PDF</span>
              </button>
              <div
                className={`sm:w-[185px] w-[160px]  h-[48px] p-2.5 cursor-pointer rounded-lg border justify-center items-center gap-2 inline-flex ${
                  isDarkMode
                    ? " bg-gray-700 border-gray-600 text-white"
                    : "bg-blue-50 border-blue-300 text-gray-700"
                }`}
              >
                <div className="items-center gap-2 flex justify-between">
                  <span className="text-white cursor-pointer">
                    <Switch
                      checked={isDarkMode}
                      onChange={toggleDarkMode}
                      checkedChildren="Dark"
                      unCheckedChildren="Light"
                    />
                  </span>
                  <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                    <div
                      className={`text-sm font-medium font-["Inter"] leading-[17.50px] ${
                        isDarkMode ? "text-white" : "text-[#282828]"
                      }`}
                    >
                      Dark Mode
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute w-full h-full">
            <div className="w-full h-full mt-8">
              <Canvas
                dpr={[window.devicePixelRatio, 2]}
                camera={{ fov: 50 }}
                onCreated={({ gl, camera }) => {
                  controlsRef.current = new OrbitControls(
                    camera,
                    gl.domElement
                  );
                  controlsRef.current.enableZoom = true;
                  controlsRef.current.minPolarAngle = Math.PI / 4;
                  controlsRef.current.maxPolarAngle = Math.PI / 2;
                  controlsRef.current.enablePan = false;
                }}
                className="w-[80%] h-full mt-5"
              >
                <PresentationControls
                  speed={1.5}
                  global
                  zoom={1}
                  polar={[-1.5, Math.PI / 2]}
                >
                  <Stage environment="sunset" shadows={false}>
                    <TeethModel
                      upperFile={upperFile[currentIndex]}
                      lowerFile={plyFile[currentIndex]}
                    />
                  </Stage>
                </PresentationControls>
              </Canvas>
            </div>
          </div>
        </div>
        <div
          className={` bottom-0 w-full flex flex-row sm:gap-x-[20px] gap-[5px] justify-between pr-[0.5rem] sm:pr-[2rem] pb-[20px]`}
        >
          <div className=" flex flex-col items-center w-full">
            <div
              className={`w-[85%] sm:h-[90px] h-[60px] rounded-lg flex flex-col items-center sm:px-16 px-4 relative  ${
                isDarkMode
                  ? " bg-gray-700 border-gray-600 text-white"
                  : "bg-blue-50 border-blue-300 text-gray-700"
              }`}
            >
              <h1 className="w-full">
                {currentIndex + 1}/
                {upperShow ? upperFile.length : plyFile.length}
              </h1>
              <div className="flex flex-col ml-r w-full">
                <div className="w-full relative">
                  <input
                    id="default-range"
                    type="range"
                    className="w-full h-0.5 mb-4 rounded-lg appearance-none cursor-pointer bg-blue-50 border-blue-300"
                    min="0"
                    max={upperShow ? upperFile.length - 1 : plyFile.length - 1}
                    value={currentIndex}
                    onChange={(e) => setCurrentIndex(Number(e.target.value))}
                    style={{
                      background: `linear-gradient(to right, white 0%, white ${
                        (currentIndex /
                          (upperShow
                            ? upperFile.length - 1
                            : plyFile.length - 1)) *
                        100
                      }%, gray ${
                        ((currentIndex + 1) /
                          (upperShow
                            ? upperFile.length - 1
                            : plyFile.length - 1)) *
                        100
                      }%, gray 100%)`,
                    }}
                  />
                  <div className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none">
                    {[
                      ...Array(upperShow ? upperFile.length : plyFile.length),
                    ].map((_, index) => (
                      <div
                        key={index}
                        className="w-0.5 h-full bg-blue-500 absolute"
                        style={{
                          left: `${
                            (index /
                              (upperShow
                                ? upperFile.length - 1
                                : plyFile.length - 1)) *
                            100
                          }%`,
                          transform: "translateX(-50%)",
                        }}
                      ></div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center w-full justify-center sm:flex-row flex-col mt-[1rem] gap-6">
              <div
                className={`sm:w-[25%] w-[60%] h-16 rounded-lg border justify-start items-center inline-flex  ${
                  isDarkMode
                    ? " bg-gray-700 border-gray-500 text-white"
                    : "bg-blue-50 border-blue-300 text-gray-700"
                }`}
              >
                <div className="grow shrink basis-0 self-stretch py-2 flex-col justify-center items-center inline-flex cursor-pointer border-r border-[#6B7280]">
                  <div className="w-5 h-5 relative">
                    <button>
                      <ArrowLeftOutlined
                        onClick={handlePrevious}
                        className={`${
                          isDarkMode ? "text-gray-50" : "text-blue-600"
                        }`}
                      />
                    </button>
                  </div>
                </div>
                <div className="grow shrink basis-0 self-stretch py-2 flex-col justify-center items-center inline-flex cursor-pointer ">
                  <div className="w-[46px] h-[46px] bg-blue-600 rounded-[999px] flex-col justify-center items-center flex">
                    <img
                      onClick={() => {
                        setPlay((prevPlay) => !prevPlay);
                        setCurrentIndex(0);
                      }}
                      src={play ? pausee : playy}
                      alt=""
                      className="w-full h-full cursor-pointer"
                    />
                  </div>
                </div>
                <div className="grow shrink basis-0 self-stretch py-2 flex-col justify-center items-center inline-flex cursor-pointer border-l border-[#6B7280]">
                  <div className="w-5 h-5 relative">
                    <button>
                      <ArrowRightOutlined
                        onClick={handleNext}
                        className={`${
                          isDarkMode ? "text-gray-50" : "text-blue-600"
                        }`}
                      />
                    </button>
                  </div>
                </div>
              </div>

              <div className="top-[70%] right-[12.85rem]">
                <div
                  onClick={() => {
                    setqPlay(() => {
                      setqPlay(!qPlay);
                    });
                    // setCurrentIndex(0);
                  }}
                  className={`cursor-pointer w-[101px] h-[45px] px-2.5 rounded-lg border justify-center items-center gap-2.5 inline-flex ${
                    isDarkMode
                      ? "text-gray-50 border-gray-500"
                      : "text-blue-600 border-blue-300 bg-blue-50"
                  }`}
                >
                  <div className="w-[21.46px] h-[20.06px] relative">
                    <img
                      src={clock}
                      height="20.06"
                      width="21.46px"
                      alt="Clock"
                      style={{ transform: "rotate(0deg)" }}
                    />
                  </div>
                  <div
                    className={`text-lg font-medium font-["Inter"] leading-[27px] ${
                      isDarkMode ? "text-gray-300" : "text-blue-600"
                    }`}
                  >
                    {qPlay == true ? "2X" : "1X"}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`flex flex-col items-center sm:h-[183px] h-[150px]  p-4 border rounded-lg justify-center ${
              isDarkMode
                ? " bg-gray-700 border-gray-500 text-white"
                : "bg-blue-50 border-blue-300 text-gray-700"
            }`}
          >
            <img
              src={!upperShow ? upper : upperA}
              onClick={() => {
                setUpperShow(true);
                setShowLeftView(false);
                setShowRightView(false);
                setLowerShow(false);
                setShowCenter(false);
              }}
              className="sm:w-[50.23px] sm:h-[44.45px] w-[40px] h-[34px] mb-1.5 cursor-pointer select-none"
              alt="Upper"
            />
            <div className="flex flex-row w-full items-center justify-center">
              <img
                onClick={() => {
                  setShowLeftView(true);
                  setShowRightView(false);
                  setUpperShow(false);
                  setLowerShow(false);
                  setShowCenter(false);
                }}
                src={!showLeftView ? left : leftA}
                className="sm:w-[38.55px] sm:h-[38.55] w-[20px] h-[20px] mr-1.5 cursor-pointer select-none"
                alt="Left"
              />
              <img
                onClick={() => {
                  setUpperShow(false);
                  setShowLeftView(false);
                  setShowRightView(false);
                  setLowerShow(false);
                  setShowCenter(true);
                }}
                src={!showCenter ? center : centerA}
                className="sm:w-[50.23px] w-[35px] cursor-pointer select-none"
                alt="Center Active"
              />
              <img
                src={!showRightView ? right : rightA}
                className="sm:w-[38.55px] sm:h-[38.55] w-[20px] h-[20px] ml-1.5 cursor-pointer select-none"
                alt="Right"
                onClick={() => {
                  console.log("upper", upperShow);
                  setUpperShow(false);
                  setShowLeftView(false);
                  setShowRightView(true);
                  setLowerShow(false);
                  setShowCenter(false);
                }}
              />
            </div>
            <img
              src={!lowerShow ? lower : lowerA}
              className="sm:w-[50.23px] sm:h-[44.45px] w-[40px] h-[34px] mt-1.5 cursor-pointer select-none"
              alt="Lower"
              onClick={() => {
                console.log("upper", upperShow);
                setUpperShow(false);
                setShowCenter(false);
                setShowLeftView(false);
                setShowRightView(false);
                setLowerShow(true);
              }}
            />
          </div>
        </div>
      </div>
      <div
        className={`${
          loading ? "" : "hidden"
        } w-[100%] h-[100%] fixed z-[500] top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 flex-col">
          <p className="text-center text-xl font-semibold text-blue-700">
            <Spin size="medium" /> &nbsp;&nbsp; Loading ...
          </p>
        </div>
      </div>
      <div
        className={`${
          loadingF ? "" : "hidden"
        } w-[100%] h-[100%] fixed z-[500] top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 flex-col">
          <p
            className={`${
              isDarkMode ? "text-blue-700" : "text-blue-700"
            } text-xl font-semibold`}
          >
            <Spin
              className={`${isDarkMode ? "text-blue-700" : "text-blue-700"}`}
            />{" "}
            &nbsp;&nbsp; Loadng Files {count} of {len}...
          </p>
        </div>
      </div>
    </main>
  );
};

export default PlayerViewer;
