import React from 'react'
import Inquiries from "../Listings/inquiriesListing"
import Nav from "../nav/nav"
const Inq = () => {
  return (
    <div>
        <Nav/>
        <Inquiries/>
    </div>
  )
}

export default Inq