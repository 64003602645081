import React from 'react'
import Inquiries from "../Listings/inquiry"
import Nav from "../nav/nav"
const Inqua = () => {
  return (
    <div>
        <Nav/>
        <Inquiries/>
    </div>
  )
}

export default Inqua