import React from 'react'
import AdminProf from "../adminDash/adminEdit"
const Dash = () => {
  return (
    <div>
        <AdminProf/>
    </div>
  )
}

export default Dash