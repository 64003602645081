import React from 'react'
import Loginn from "../Auth/login"
const Login = () => {
  return (
    <div>
        <Loginn/>
    </div>
  )
}

export default Login