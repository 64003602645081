import React from 'react'
import Orders from "../Listings/orderslisting"
import Nav from "../nav/nav"
const Dash = () => {
  return (
    <div>
        <Nav/>
        <Orders/>
    </div>
  )
}

export default Dash