import React from 'react'
import Profile from "../profile/profile"
import Nav from "../nav/nav"
const Pro = () => {
  return (
    <div>
        <Nav/>
        <Profile/>
    </div>
  )
}

export default Pro