import React from 'react'
import Support from "../profile/support"
import Nav from "../nav/nav"
const Sup = () => {
  return (
    <div>
        <Nav/>
        <Support/>
    </div>
  )
}

export default Sup