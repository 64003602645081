import React from 'react'
import DashBoard from "../adminDash/adminDash"
const Dash = () => {
  return (
    <div>
        <DashBoard/>
    </div>
  )
}

export default Dash