import React from 'react'
import Tasks from "../Listings/tasksListing"
import Nav from "../nav/nav"
const Inq = () => {
  return (
    <div>
        <Nav/>
        <Tasks/>
    </div>
  )
}

export default Inq