import React from 'react'
import ProfileEdit from "../profile/editprofile"
import Nav from "../nav/nav"
const ProE = () => {
  return (
    <div>
        <Nav/>
        <ProfileEdit/>
    </div>
  )
}

export default ProE