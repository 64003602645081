import React from 'react'
import DashBoard from "../Dash/dashBoard"
import Nav from "../nav/nav"
const Dash = () => {
  return (
    <div>
        <Nav/>
        <DashBoard/>
    </div>
  )
}

export default Dash