import React from 'react'
import Clinic from "../Listings/clinicslisting"
import Nav from "../nav/nav"
const Dash = () => {
  return (
    <div>
        <Nav/>
        <Clinic/>
    </div>
  )
}

export default Dash